import "styles/pages/page-culture.scss";
import React from "react";

import Layout from "components/Layout";
import Seo from "components/Seo";
import SubpageHeader from "components/SubpageHeader";
import TextWithSymbol from "components/TextWithSymbol";
import Breadcrumbs from "../components/Breadcrumbs";

const PageCulture = ({ pageContext }) => {
	const pageCulture = pageContext.pageContent;
	return (
		<Layout headerColor="#54E0F7">
			<Seo
				title={
					pageContext.seo.title
						? pageContext.seo.title
						: pageContext.title
				}
				description={
					pageContext.seo.metaDesc && pageContext.seo.metaDesc
				}
			/>
			<SubpageHeader
				image={require("assets/images/culture-header-bg.png").default}
			/>
			<Breadcrumbs currentLocationLabel="Kultura" />
			<section className="culture-welcome">
				<div className="container">
					<h2 className="section-title culture-welcome__title">
						{pageCulture.welcomeTitle}
					</h2>
					<div className="row">
						<div className="col-xl-6 col-md-7 offset-xl-1">
							<div
								className="culture-welcome__text"
								dangerouslySetInnerHTML={{
									__html: pageCulture.welcomeDescFirstPart,
								}}
							/>
							<TextWithSymbol
								title="Zwykłe powitanie"
								desc="Kiedy witasz się w zwykły sposób, musisz złożyć ręce na wysokości klatki piersiowej i położyć palec serdeczny na czole. "
							/>
							<TextWithSymbol
								title="Wysokie powitanie"
								desc="Drugi sposób to wysokie powitanie. Tutaj musisz podnieść ręce jak najwyżej, co oznacza głęboki szacunek dla osoby z którą się witasz."
							/>
							<div
								className="culture-welcome__text"
								dangerouslySetInnerHTML={{
									__html: pageCulture.welcomeDescSecondPart,
								}}
							/>
						</div>
						<div className="col-xl-4 col-md-5 offset-xl-1">
							<img
								src={pageCulture.welcomeImage?.sourceUrl}
								alt=""
								className="img-fluid culture-welcome__img"
							/>
						</div>
					</div>
				</div>
			</section>
			<section className="culture-architecture">
				<div className="container">
					<div className="row flex-md-row flex-column-reverse">
						<div className="col-xl-7 col-md-6">
							<img
								src={pageCulture.architectureImage1?.sourceUrl}
								alt=""
								className="img-fluid culture-architecture__img"
							/>
							<img
								src={pageCulture.architectureImage2.sourceUrl}
								alt=""
								className="img-fluid culture-architecture__img"
							/>
						</div>
						<div className="col-xl-5 col-md-6">
							<div className="culture-architecture__text-container">
								<h2 className="section-title culture-architecture__title">
									{pageCulture.architectureTitle}
								</h2>
								<div
									className="culture-architecture__text"
									dangerouslySetInnerHTML={{
										__html: pageCulture.architectureDesc,
									}}
								/>
							</div>
						</div>
					</div>
				</div>
			</section>
			<section className="culture-dance">
				<div className="container">
					<h2 className="section-title">{pageCulture.danceTitle}</h2>
					<div className="row">
						<div className="col-xl-5 col-lg-6 offset-xl-1">
							<div
								className="culture-dance__text"
								dangerouslySetInnerHTML={{
									__html: pageCulture.danceDesc,
								}}
							/>
							{pageCulture.danceVersions?.map((node, index) => {
								return (
									<TextWithSymbol
										title={node.title}
										desc={node.desc}
										key={index}
									/>
								);
							})}
						</div>
						<div className="col-lg-6">
							<img
								src={pageCulture.danceImg1?.sourceUrl}
								alt=""
								className="img-fluid culture-dance__img"
							/>
							<img
								src={pageCulture.danceImg2?.sourceUrl}
								alt=""
								className="img-fluid culture-dance__img"
							/>
						</div>
					</div>
				</div>
			</section>
			<section className="culture-music">
				<div className="container">
					<div className="row flex-md-row flex-column-reverse">
						<div className="col-md-6">
							<img
								src={pageCulture.musicImg?.sourceUrl}
								alt=""
								className="img-fluid"
							/>
						</div>
						<div className="col-xl-5 col-md-6 offset-xl-1">
							<div className="culture-music__text-container">
								<h2 className="section-title">
									{pageCulture.musicTitle}
								</h2>
								<div
									className="culture-music__text"
									dangerouslySetInnerHTML={{
										__html: pageCulture.musicDesc,
									}}
								/>
							</div>
						</div>
					</div>
				</div>
			</section>
			<section className="culture-suits">
				<div className="container">
					<div className="row">
						<div className="col-md-6">
							<h2 className="section-title">
								{pageCulture.outfitsTitle}
							</h2>
							<div
								className="culture-suits__text"
								dangerouslySetInnerHTML={{
									__html: pageCulture.outiftsDesc,
								}}
							/>
						</div>
						<div className="col-xl-5 col-md-6 offset-xl-1">
							<img
								src={pageCulture.outfitsImg?.sourceUrl}
								alt=""
								className="img-fluid culture-suits__img"
							/>
						</div>
					</div>
				</div>
			</section>
		</Layout>
	);
};

export default PageCulture;
